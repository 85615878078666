<template>
    <header :class="advanceMenu && 'sidebar_hide'">
        <div class="left_block flex items-center">
            <div class="head_logo ml-2">
                <router-link
                    v-if="logo"
                    :to="{name: frontPage}"
                    class="logo">
                    <img
                        :data-src="logo"
                        class="lazyload">
                </router-link>
            </div>
            <AddButton class="ml-4" />
        </div>
        <div class="actions_block">
            <component :is="workPlan" />
            <component :is="calendar" />
            <component :is="headerMenu" />
            <!--<component :is="returnWidget" />-->
            <component :is="cart" />
            <component :is="pwaBanner" />
            <component :is="notification" />
            <component :is="support" />
            <UserNav />
        </div>
    </header>
</template>

<script>
import UserNav from './UserNav.vue'
import {mapState} from 'vuex'
import mixins from './mixins'
import 'lazysizes'
import Bell from '@apps/Notifications/views/Bell'
import AddButton from './AddButton.vue'
export default {
    mixins: [
        mixins
    ],
    components: {
        UserNav,
        Bell,
        AddButton
    },
    computed: {
        ...mapState({
            advanceMenu: state => state.navigation.advanceMenu,
            config: state => state.config.config,
            asideType: state => state.asideType,
            routers: state => state.navigation.routerList
        }),
        logo() {
            if(this.config?.header_setting?.logo)
                return this.config.header_setting.logo
            else
                return null
        },
        frontPage() {
            if(this.routers?.length) {
                return this.routers[0].name
            } else
                return ''
        }
    },
    data() {
        return {
            appConfig: null
        }
    },
    created() {
        this.appConfig = JSON.parse(JSON.stringify(this.config))
    }
}
</script>

<style lang="scss" scoped>
header{
    background: var(--eBg);
    height: var(--headerHeight);
    border-bottom: 1px solid var(--border2);
    padding: 5px 15px 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    min-height: 50px;
    max-height: 50px;
    .head_logo{
        img{
            max-width: 160px;
            max-height: 45px;
            opacity: 0;
            transition: opacity .1s ease-in-out;
            min-width: 70px;
            &.lazyloaded{
                opacity: 1;
            }
        }
    }
    .left_block{
        .menu_btn{
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: #eff2f5;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            margin-right: 15px;
        }
    }
    &.sidebar_hide{
        left: 75px;
    }
    .actions_block{
        display: flex;
        align-items: center;
        .action_btn{
            &:not(:last-child){
                margin-right: 15px;
            }
            &::v-deep{
                .ant-btn{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background: #eff2f5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                }
            }
        }
    }
}
</style>
